import React, { useContext } from "react";
import Header from "../../Components/Layout/Header";
import Dealerimage from "../../Assets/images/Image 202.png";
import { gigContext } from "../Gig";
import { assignBucketUrl } from "../../HelperFunctions";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../Utils/Loader";
import Layout from "../../Components/Layout";

function DealerDetails() {
  const { gigState, orderId, pageId, loader } = useContext(gigContext);
  const dealer = gigState?.gigData?.data;
  const navigate = useNavigate();

  return (
    <div>
      {loader ? (
        <div style={{ marginTop: "6rem", textAlign: "center" }}>
          <Loader />
        </div>
      ) : (
        <>
          <Layout>
          <div className='container'>
            <h4 className='pt-3 p-16 fw-bold'>Dealer's Details</h4>
            <div className='text-center'>
              <img src={Dealerimage} className='img-fluid' alt='#' />
            </div>

            <div className=''>
              <div className='card mb-3 border-0' style={{ boxShadow: "0px 0px 3px #E0E0E0" }}>
                <div className='card-body'>
                  {/* <div className='d-flex align-items-center justify-content-between  pb-2'>
                    <div className='d-flex align-items-center'>
                      <div className='' style={{ height: " 49px", width: "54px" }}>
                        <img
                          src={assignBucketUrl(dealer?.profilePicture)}
                          onError={(event) => {
                            event.target.src =
                              "https://driver101.s3.us-west-1.amazonaws.com/upload/files/Portrait_Placeholder.png";
                            event.onerror = null;
                          }}
                          alt='#'
                          style={{ height: "100%", width: "100%", objectFit: "cover" }}
                          className='rounded-2'
                        />
                      </div>
                      <div className='ms-2'>
                        <p className='m-0  text-fade p-12'>Dealer Name</p>
                        <p className='m-0 fw-semibold dark-clr p14'>{dealer?.name}</p>
                      </div>
                      
                    </div>
                  </div> */}
                  <div >
                    <h6 className="mb-2 fw-bold dark-clr">Dealer Information</h6>
                    <div className='my-2' style={{ height: "55px", width: "55px" }}>
                        <img
                          src={assignBucketUrl(dealer?.profilePicture)}
                          onError={(event) => {
                            event.target.src =
                              "https://driver101.s3.us-west-1.amazonaws.com/upload/files/Portrait_Placeholder.png";
                            event.onerror = null;
                          }}
                          alt='#'
                          style={{ height: "100%", width: "100%", objectFit: "cover" }}
                          className='rounded-2'
                        />
                      </div>
                    <div className='row  mb-2'>
                      <div className='col-5 text-fade p-12'>Name</div>
                        <div className='col-1 p-12 text-fade'>:</div>
                      <div className='col-5 text-black fw-semibold p-14 '>{`${dealer?.name}`}</div>
                    </div>
                    <div className='row  mb-2'>
                      <div className='col-5 text-fade p-12'>Phone </div>
                        <div className='col-1 p-12 text-fade'>:</div>
                      <div className='col-5 text-black fw-semibold p-14 '>{`(${dealer?.phone?.slice(
                        2,
                        5
                      )}) ${dealer?.phone?.slice(5, 8)}-${dealer?.phone?.slice(8, 12)}`}</div>
                    </div>
                    <div className='row  mb-2'>
                      <div className='col-5 text-fade p-12'>Address</div>
                        <div className='col-1 p-12 text-fade'>:</div>
                      <div className='col-5 text-black fw-semibold p-14 '>{dealer?.address}</div>
                    </div> 
                    {/* <div className="row align-items-center">
                    <div className='col-md-auto text-fade p-12'>
                    <div className='my-2' style={{ height: "100px", width: "100px" }}>
                        <img
                          src={assignBucketUrl(dealer?.profilePicture)}
                          onError={(event) => {
                            event.target.src =
                              "https://driver101.s3.us-west-1.amazonaws.com/upload/files/Portrait_Placeholder.png";
                            event.onerror = null;
                          }}
                          alt='#'
                          style={{ height: "100%", width: "100%", objectFit: "cover" }}
                          className='rounded-2 border'
                        />
                      </div>
                    </div>
                      <div className='col-md-1 p-12 text-fade d-md-block d-none'>:</div>
                      <div className='col-md-auto text-black fw-semibold p-14 mb-2 '>
                      <div className=' text-black fw-semibold p-14 mb-2'><i class="bi bi-person-fill me-2"></i>{`${dealer?.name}`}</div>
                      <div className=' text-black fw-semibold p-14 mb-2'><i class="bi bi-telephone-fill me-2"></i>{`(${dealer?.customerPhone?.slice(
                        2,
                        5
                      )}) ${dealer?.phone?.slice(5, 8)}-${dealer?.phone?.slice(8, 12)}`}</div>
                      <div className=' text-black fw-semibold p-14 d-flex'><div><i class="bi bi-geo-alt-fill me-2"></i></div>{dealer?.address}</div>
                      </div>
                    </div> */}
                        <hr/>
                    <h6 className="mb-2 fw-bold dark-clr">Customer Information</h6>
                    <div className='row  mb-2'>
                      <div className='col-5 text-fade p-12'>Name</div>
                      <div className='col-1 p-12 text-fade'>:</div>
                      <div className='col-5 text-black fw-semibold p-14 '>{dealer?.customerFullName || "-"}</div>
                    </div>
                    <div className='row  mb-2'>
                      <div className='col-5 text-fade p-12'>Phone </div>
                        <div className='col-1 p-12 text-fade'>:</div>
                      <div className='col-5 text-black fw-semibold p-14 '>{`(${dealer?.customerPhone?.slice(
                        2,
                        5
                      )}) ${dealer?.customerPhone?.slice(5, 8)}-${dealer?.customerPhone?.slice(8, 12)}`}</div>
                    </div>
                    <div className='row  mb-2'>
                      <div className='col-5 text-fade p-12'>Email</div>
                      <div className='col-1 p-12 text-fade'>:</div>
                      <div className='col-5 text-black fw-semibold p-14 '>{dealer?.customerEmail || "-"}</div>
                    </div>
                    <div className='row  mb-2'>
                      <div className='col-5 text-fade p-12'>Other Information</div>
                      <div className='col-1 p-12 text-fade'>:</div>
                      <div className='col-5 text-black fw-semibold p-14 '>{dealer?.customerOtherInfos || "-"}</div>
                    </div>
                    <hr/>
                    <h6 className="mb-2 fw-bold dark-clr">Vehicle Information</h6>

                    <div className='row  mb-2'>
                      <div className='col-5 text-fade p-12'>Car make</div>
                      <div className='col-1 p-12 text-fade'>:</div>
                      <div className='col-5 text-black fw-semibold p-14 '>{dealer?.vehicleMake || "-"}</div>
                    </div>
                    <div className='row  mb-2'>
                      <div className='col-5 text-fade p-12'>Car model</div>
                      <div className='col-1 p-12 text-fade'>:</div>
                      <div className='col-5 text-black fw-semibold p-14 '>{dealer?.vehicleModel || "-"}</div>
                    </div>
                    <div className='row  mb-2'>
                      <div className='col-5 text-fade p-12'>Car color</div>
                      <div className='col-1 p-12 text-fade'>:</div>
                      <div className='col-5 text-black fw-semibold p-14 '>{dealer?.vehicleColor || "-"}</div>
                    </div>{" "}
                    <div className='row  mb-2'>
                      <div className='col-5 text-fade p-12'>Other information</div>
                      <div className='col-1 p-12 text-fade'>:</div>
                      <div className='col-5 text-black fw-semibold p-14 '>{dealer?.vehicleOtherInformation || "-"}</div>
                    </div>

                    <div className='row  mb-2'>
                      <div className='col-5 text-fade p-12'>Pickup address</div>
                      <div className='col-1 p-12 text-fade'>:</div>
                      <div className='col-5 text-black fw-semibold p-14 '>{dealer?.pickupAddress || "-"}</div>
                    </div>

                    <div className='row  mb-2'>
                      <div className='col-5 text-fade p-12'>Dropoff address</div>
                      <div className='col-1 p-12 text-fade'>:</div>
                      <div className='col-5 text-black fw-semibold p-14 '>{dealer?.dropOffAddress || "-"}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=' mb-3'>
              <button
                onClick={() => {
                    if (gigState?.gigData?.data?.photosBeforePick?.length > 0) {
                      navigate(`/live-status/${orderId}`);
                    } else {
                      navigate(`/gig-details/${orderId}/${pageId + 1}`);
                    }
                }}
                className='button  btn-primary w-100 py-3 '>
                Continue
              </button>
            </div>
            <div className="extraHeight"></div>
          </div>
          </Layout>
        </>
      )}
    </div>
  );
}

export default DealerDetails;
